<script lang="ts">
  import logoSvg from '$lib/assets/svg/logo_salmon_bg.svg';

  export let inline = false;
  export let withTypo = false;
</script>

{#if !withTypo}
  <img
    src={logoSvg}
    alt="pome Logo"
    class="h-12 w-12 {inline ? 'inline' : ''}"
  />
{:else}
  <div>
    <a class="flex flex-shrink-0 items-center gap-x-2 text-xl" href="/#">
      <img
        src={logoSvg}
        alt="Pome Logo"
        class="h-12 w-12 {inline ? 'inline' : ''}"
      />
      <span class="logo-typo text-to-crop text-3xl">pome.gr</span>
    </a>
  </div>
{/if}
